.train_card {
    position: relative;
    width: 60%;
    min-width: 300px;
    min-height: 190px;
    margin: 0 auto;
    overflow: hidden;
    color: #FFF;
    text-align: left;
    background-color: rgb(14 13 22);
    border-radius: 8px;
    cursor: pointer;
}

.train_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    filter: brightness(50%)
}

.train_text {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 18px;
}

.train_card p {
    margin: 0 0 8px;
}

.train_name {
    font-weight: 700;
    font-size: 24px;
}

.train_description {
    font-size: 16px;
}

.train_length {
    position: absolute;
    right: 18px;
    bottom: 14px;
    font-weight: 700;
    font-size: 26px;
}

.train_btn {
    position: absolute;
    bottom: 18px;
    left: 18px;
    color: #FFF;
    font-weight: 700;
    background-color: rgb(22 198 139);
    border: none;
}

.train_btn:hover {
    color: #FFF !important;
    transform: translateY(2px);
}

@media screen and (width <= 500px) {
    .train_card {
        /* To let text fit on narrow screens */
        min-height: 240px;
    }
}