.chat_trainings {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 80%;
    max-width: 800px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    background-color:rgb(39 33 52);
}

ul {
    flex: 1;
    padding: 0;
    overflow-y: auto;
}

.chat_trainings .line {
    padding: 5px;
    text-align: left;
}

.line.__right {
    text-align: right;
}

.chat_trainings .line li {
    list-style-type: none;
}

.chat_trainings .message {
    position: relative;
    display: inline-block;
    margin: 0 15px;
    padding: 10px;
    color: #FFF;
    white-space: pre-wrap;
    background-color: rgb(97 79 143);

    /* border: 1px solid rgb(99, 88, 197); */
    border-radius: 10px;
}

.chat_trainings .message::before {
    position: absolute;
    bottom: 0;
    left: -11px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid rgb(97 79 143);
    border-bottom: 10px solid rgb(97 79 143);
    border-left: 10px solid transparent;
    content: "";
}

.chat_trainings .line.__right .message {
    background-color: rgb(102 169 69);

    /* border: 1px solid rgb(112, 243, 117); */
}

.chat_trainings .line.__right .message::before {
    position: absolute;
    right: -11px;
    bottom: 0;
    left: auto;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgb(102 169 69);
    border-left: 10px solid rgb(102 169 69);
    content: "";
}

.satisfaction_cnt {
    position: relative;
}

.chat_progress_cnt {
    width: 100%;
    height: 8px;
    overflow: hidden;
    background-color: #333;
    border-radius: 4px;
}

.chat_progress {
    height: 100%;
    background-color: rgb(45 182 21);
    transition: background-color 1s, width 1s;
}

.chat_progress_cnt.changing {
    box-shadow: 0 0 0 0 rgb(255 255 255 / 100%);
    transform: scale(1);
    animation: pulse 2s;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgb(255 255 255 / 70%);
        transform: scale(1);
    }

    70% {
        box-shadow: 0 0 0 15px rgb(255 255 255 / 0%);
        transform: scale(1.05);
    }

    100% {
        box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
        transform: scale(1);
    }
}

.emoji {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: none;
    width: 100px;
    height: 100px;
    margin: -80px -50px;
    border-radius: 999px;

    /* transform: translateX(-50%) translateY(-85%); */
    transform-origin: 50% 50%;
}

.emoji.visible {
    display: block;
    animation: appear 1s 1;
    animation-iteration-count: 1;
}

.emoji.hidden {
    display: block;
    animation: disappear .2s;
    animation-iteration-count: 1;
}

@keyframes appear {
    0% {
        transform: scale(.1);
    }

    70% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes disappear {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.1);
    }
}

@media screen and (width <= 500px) {
    .chat {
        width: 100%;
        padding: 0;
    }

    .satisfaction_cnt {
        padding: 0 5px;
    }
}