
.chat_trainings .send_block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.chat_trainings .send_input_cnt {
    flex: 8;
}

.chat_trainings .text_back {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 70px;
    background-color: #000;
    border-radius: 8px;
}

.chat_trainings .send_input {
    max-height: 210px;
    margin: 0 4px;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    color: #FFF;
    font-size: 18px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none !important; /* antd styles */
}

.chat_trainings .send_input::placeholder {
    color: #FFF;
    opacity: 0.8;
}

.chat_trainings .chars_counter {
    position: absolute;
    top: -10px;
    right: 0;
    color: #777;
    font-weight: 300;
    font-size: 14px;
}

/* .recording {
    width: 22px;
    height: 22px;
    background-color: rgb(200, 13, 13);
    border-radius: 99px;
    animation: recording_active 1s infinite;
}

@keyframes recording_active {
	0% {
		transform: scale(1);
	}
	70% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
} */

.send_button_wr {
    flex: 1;
}

.send_button_cnt {
    display: inline-block;
    padding: 3px 10px;
}

.send_button {
    display: inline-block;
    width: 60px;
    height: 60px;
    background-color: #FFF;
    background-image: url('../../../public/assets/send.png');
    background-size: cover;
    border-radius: 999px;
    cursor: pointer;
}

.send_button.disabled {
    background-image: url('../../../public/assets/send_disabled.png');
    cursor: auto;
}

.send_button:hover {
    filter: drop-shadow(0 0 6px #23B3D3);
}

.send_button.disabled:hover {
    filter: none;
}

.audio_recorder {
    position: relative;
    display: inline-block;
    flex: 1;
    padding: 5px 10px;
}

.audio_status {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    background: rgb(104 193 133);
    background: linear-gradient(180deg, rgb(104 193 133 / 100%) 0%, rgb(1 158 250 / 100%) 100%);
    border-radius: 999px;
}

.audio_status_layer {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgb(22 198 139);
    border-radius: 999px;
    opacity: 1;
}

.audio_status_layer.__1 {
    opacity: 0.6;
    animation: vibrate1 2s infinite;
}

.audio_status_layer.__2 {
    opacity: 0.2;
    animation: vibrate2 4s infinite;
}

.audio_status_layer.__micro {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    background-image: url('../../../public/assets/voice.png');
    background-size: contain;
    cursor: pointer;
}

.audio_status_layer.__micro:hover {
    filter: drop-shadow(0 0 6px rgb(104 193 133));
}

@keyframes vibrate1 {
    0% {
        transform: scale(1);
    }

    70% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes vibrate2 {
    0% {
        transform: scale(1.1);
    }

    25% {
        transform: scale(1.55);
    }

    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(1.55);
    }

    100% {
        transform: scale(1.1);
    }
}

.transcribing_progress {
    display: block;
    width: 50px;
    height: 50px;
    padding: 5px;
}

.transcribing_progress > svg {
    width: 100%;
    height: 100%;
}

.pause_cnt {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 12px 8px 8px;
    color: #FFF;
    font-size: 28px;
}

.pause_btn {
    display: inline-block;
    width: 60px;
    height: 60px;
    background-image: url('../../../public/assets/stop.png');
    background-size: contain;
    cursor: pointer;
}

.pause_btn:hover {
    filter: drop-shadow(0 0 6px #EF5350);
}

.pause_timer {
    display: inline-block;
    min-width: 80px;
    padding: 0 6px;
    text-align: center;
}

.input_menu_wr {
    flex: 1;
}

.input_menu_cnt {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin: 0 5px;
}

.input_menu_btn {
    display: block;
    padding: 12px;
    color: #FFF;
    background-color: transparent;
    border: 1px solid rgb(250 250 250 / 70%);
    border-radius: 8px;
    cursor: pointer;
}

.input_menu_btn > span,
.input_menu_btn svg {
    width: 100%;
    height: 100%;
}

.input_menu_overlay li.ant-dropdown-menu-item {
    padding: 10px 12px !important;
    font-size: 18px !important;
}

.input_menu_overlay span.anticon {
    font-size: 18px !important;
}

@media screen and (width <= 500px) {
    .send_block {
        padding-bottom: 5px !important;
    }
}