html, body {
    position: fixed;
    width: 100%;
    height: 100%;
}

body {
    color: #FFF;
    font-size: 18px;

    /* background: linear-gradient(rgb(23, 58, 161), rgb(229, 124, 82)); */
    background-color: #222;
}

#root {
    height: 100%;
    overflow-y: auto;
}

.section {
    height: 100%;
}

.island {
    box-sizing: border-box;
    width: 80%;
    max-width: 471px;
    margin: 0 auto;
    padding: 60px 60px 48px;;
    color: #444;
    background: #000;
    border-radius: 20px;
}

.title_cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    margin-bottom: 40px;
}

.island.fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

h1 {
    font-size: 42px;
}

h2 {
    color: #FFF;
    font-weight: 700;
    font-size: 36px;
    font-family: Inter, sans-serif;
    line-height: 36px;
}

.mt20 {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.mt40 > a {
    color: #FFF;
    font-weight: 400;
    font-size: 14px;
    font-family: Inter, sans-serif;
    line-height: 14px;

    /* margin-top: 40px; */
    text-decoration: none;
    opacity: 0.6;
}

.white {
    color: #FFF
}

.control {
    max-width: 351px;
    margin: 24px 0;
    padding: 2px 12px 8px;
    text-align: left;
    background-color: rgb(15 15 15);
    border-bottom: 1px solid rgb(87 87 87);
}

.control_no_input {
    display: flex;
    align-items: center;
    height: 54px;
    margin: 24px 0;
    text-align: left;
    background-color: rgb(15 15 15);
    border-bottom: 1px solid rgb(87 87 87);
}

.control_no_input:hover {
    background-color: rgb(31 31 31);
}

.control_no_input > span {
    margin-left: 10px;
    color: #FFF;
    font-weight: 500;
    font-size: 16px;
    font-family: Inter, sans-serif;
    line-height: 20.8px;
    opacity: 0.5;
}

.control > span {
    color: #FFF;
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    line-height: 15.6px;
    opacity: 0.5;
}

.control .ant-input {
    max-width: 100%;
    padding: 0;
    color: #FFF;
    font-weight: 500;
    font-size: 16px;
    font-family: Inter, sans-serif;
    line-height: 20.8px;
    background-color: rgb(15 15 15) !important;
    border: none;
    border-radius: 0;
}

.control:hover {
    background-color: rgb(31 31 31);
    border-bottom: 1px solid #FFF;
}

.control:hover .ant-input {
    background-color: rgb(31 31 31);
}

.control .ant-input:focus {
    box-shadow: none;
}

.control .ant-input:not(:placeholder-shown) {
    background-color: transparent !important;
}

.control .ant-input:-webkit-autofill,
.control .ant-input:-webkit-autofill:hover,
.control .ant-input:-webkit-autofill:focus,
.control .ant-input:-webkit-autofill:active  {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #FFF !important;
}

/* checkbox */
.checkbox_cnt {
    text-align: left;
}

.checkbox_cnt .terms_text {
    margin-left: 8px;
    color: #FFF;
    font-weight: 400;
    font-size: 14px;
    font-family: Inter, sans-serif;
    line-height: 14px;
    opacity: 0.6;
}

.checkbox_cnt .terms_text > a {
    color: #FFF;
    text-decoration: underline;
}

.checkbox_cnt .ant-checkbox-inner {
    background-color: #000;
    border: 2px solid #FFF;
    opacity: 0.6;
}

.checkbox_cnt .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #08F49F;
    border: 2px solid #08F49F;
    opacity: 1;
}

.checkbox_cnt .ant-checkbox:hover .ant-checkbox-inner,
.checkbox_cnt .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border: 2px solid #FFF;
    opacity: 1;
}

.checkbox_cnt .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid #000;
    border-top: 0;
    border-inline-start: 0;
}

.checkbox_cnt .ant-checkbox-checked:hover .ant-checkbox-inner,
.checkbox_cnt .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #08F49F !important;
    border: 2px solid none;
    opacity: 1;
}

.checkbox_cnt .ant-checkbox-wrapper:hover .ant-checkbox-checked::after {
    border: none;
}

/* button */
.mt20 .ant-btn {
    min-width: 89px;
    height: 48px;
    padding: 12px 20px;
    background-color: #08F49F !important;
    border-radius: 4px;
}

.mt20 .ant-btn > span {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    font-family: Inter, sans-serif;
    line-height: 23.4px;
}

.App {
    height: 100%;
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112 76 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

.page_loader {
    position: fixed;
    inset: 0;
    background-color: #000;
    opacity: 0.5;
}

.top {
    padding: 20px 0 10px;
}

.top h2 {
    margin: 0 auto;
}

.logo {
    width: 120px;
    height: 90px;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.top .logo {
    margin: 0 auto 20px;
}

.top_actions {
    position: absolute;
    top: 10px;
    left: 20px;
}

.btn {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 0;
    color: #FFF;
    font-size: 30px;
    background-color: transparent;
    border-radius: 8px;
}

.btn svg {
    width: 100%;
    height: 100%;
}

.btn.disabled {
    color: #666;
    border-color: #666;
    cursor: auto;
    pointer-events: none;
}

.btn.menu_btn {
    width: 70px;
    height: 70px;
    padding: 10px;
}

.btn_primary {
    color: #FFF;
    background-color: rgb(22 198 139);
    border: none;
}

.btn_primary:hover {
    color: #FFF !important;
    transform: translateY(2px);
}

.menu_panel {
    color: #222;
    font-size: 18px;
}

.menu_action {
    box-sizing: content-box;
    margin: 0;
    padding: 15px 5px;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
}

.menu_action:hover {
    background-color: #EFEFEF;
    border-color: #EEE;
}

.menu_action.disabled {
    color: #999;
    cursor: auto;
}

.menu_action.disabled:hover {
    background-color: transparent;
    border-color: transparent;
}


@media screen and (width <= 500px) {
    .top_actions {
        top: 0;
        left: 0;
    }
}

/* -------------------- */

/* antd style overrides */

/* -------------------- */

/* .ant-btn:hover,
.ant-btn-primary:hover {
    border-color: #fff !important;
    background-color: rgb(22, 198, 139) !important;
} */

.ant-btn:hover svg path,
.ant-btn-primary:hover svg path {
    color:#FFF !important;
}

/* -------------------- */

/* antd style overrides */

/* -------------------- */


@media screen and (width <= 500px) {
    .top {
        flex-wrap: wrap;
    }

    .btn.menu_btn {
        margin: 5px;
    }
}
