.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
}

.navigation_title {
    display: inline-block;
}

.navigation .navig_back {
    display: inline-block;
    padding: 10px;
    font-size: 30px;
    cursor: pointer;
}

.navigation h2 {
    display: inline-block;
}

.navigator_actions {
    padding-top: 4px;
}

@media screen and (width <= 500px) {
    .navigation {
        padding: 0 5px;
    }
}